body {
  margin: 0;
  min-width: 772px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-container, .mantine-AppShell-main {
  background-image: url("assets/images/background-desktop.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  position: relative;
}

.notification-provider {
  width: 400px;
  max-width: 400px;
  top: calc(var(--mantine-header-height, 0px) + 24px);
  right: 24px;
}
